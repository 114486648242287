<script setup>
	import { useWindowStore } from '~/stores/window';
	const { $bus } = useNuxtApp();

	const props = defineProps({
		card: {
			type: Object,
		},
	});

	const { card } = toRefs(props);

	const itemsRef = ref(null);

	const textBox = ref(null);

	const isExpanded = ref(false);

	const isTruncated = ref(false);

	const windowStore = useWindowStore();

	const screenWidth = computed(() => {
		return windowStore.innerWidth;
	});

	watch(screenWidth, (newWidth) => {
		if (textBox.value) {
			checkIsTruncated(textBox.value);
		}
	});

	function checkIsTruncated(textBox) {
		if (textBox) {
			const isTruncatedResult = textBox.offsetWidth < textBox.scrollWidth;
			isTruncated.value = isTruncatedResult;
		}
	}

	const imageSrc = computed(() => {
		return card?.value?.src || card?.value?.image?.src || card?.value?.image;
	});

	const imageAlt = computed(() => {
		return card?.value?.alt || card?.value?.image?.alt || card?.value?.imageAltText;
	});

	const caption = computed(() => {
		return card?.value?.caption || card?.value?.credit;
	});

	onMounted(async () => {
		await nextTick();
		checkIsTruncated(textBox.value);
	});
</script>

<template>
	<li class="caption-card" ref="itemsRef">
		<MessImage :src="imageSrc" :alt="imageAlt" :lazy="false" />
		<div v-if="caption?.length" class="text-box fs-14" :class="[{ truncated: isTruncated }, { expanded: isExpanded }]">
			<p :class="[{ truncated: isTruncated }, { expanded: isExpanded }]" ref="textBox">{{ caption }}</p>
			<button :class="{ visible: isTruncated }" class="toggle-text-button" @click="isExpanded = !isExpanded">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
					<path d="M0 12.5H24" stroke="currentColor" stroke-width="2" class="horizontal" />
					<path v-if="!isExpanded" d="M12 24.5L12 0.5" stroke="currentColor" stroke-width="2" class="vertical" />
				</svg>
			</button>
		</div>
	</li>
</template>

<style lang="scss">
	//TODO
	//specificity is borked from a refactor. Gotta fix this later.
	.simple-carousel ul li.caption-card {
		display: block;
		position: relative;
		margin-bottom: 1rem;
		width: 1%;
		overflow: hidden;
		background-color: var(--gray-300);

		.mess-image {
			width: 100%;
			height: 100%;
			aspect-ratio: 1570 / 1038;

			picture img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.text-box {
			position: absolute;
			display: flex;
			bottom: 0;
			left: 0;
			width: var(--fraction);
			background-color: var(--gray-100);
			padding: 1rem 1.5rem;
			transition: height 0.33s ease;

			p {
				max-width: 94%;
				&:not(.expanded) {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		// Removes LI's from the box model
		position: absolute;
		width: 100%;
		height: calc(100% - 1rem);
		flex-grow: 0;

		// Hack to get the LI's to position correctly
		margin-left: calc(var(--fraction) * var(--offset));
		left: 0;

		.mess-image {
			width: var(--fraction);
			img {
				object-fit: contain;
			}
		}

		// Targets the first LI and adds it back to the box model so that
		// the size of the container is calculated correctly
		&:nth-child(1) {
			width: var((--fraction));
			max-height: 100%;
			position: relative;
			height: auto;

			.mess-image,
			.text-box {
				width: 100%;
				img {
					object-fit: cover;
				}
			}
		}

		.toggle-text-button {
			&.visible {
				display: block;
			}

			display: none;
			position: relative;
			line-height: 0;
			margin: 0 auto;

			svg {
				position: relative;
				top: 0;
				height: 1rem;
				transition: transform 0.33s ease;

				&.expanded {
					transform: rotate(45deg);
				}
			}
		}
	}
</style>
